import { REMOVE_FROM_URL } from '../helpers/url/UrlRouteQueryDecorator';
import { safeGet } from '../helpers/utils';

import { FilterRequestAPI, SearchFilterTypes } from '../Filters/FilterTypes';
import { UrlSeoService } from '../SEOParamService';

type QueryParams = {
  [key: string]: string;
};

export class UrlService {
  constructor(private SEOParams: UrlSeoService) {} // inject service mainly for test reasons

  private checkIfFilterObjectIsShouldBeIgnored(filterObject: any) {
    return filterObject.name === 'adType';
  }

  private tidyQueryParams(currentQueryParams: any) {
    delete currentQueryParams.from;
    REMOVE_FROM_URL.forEach((key: string) => {
      delete currentQueryParams[`${key}`];
    });
    return { ...currentQueryParams };
  }

  buildUrlQueryParams(filterObject: any, currentQuery: any) {
    const isEmpty = '';
    const updatedQueryParams = this.tidyQueryParams(currentQuery);

    if (this.checkIfFilterObjectIsShouldBeIgnored(filterObject)) {
      return updatedQueryParams;
    }

    switch (filterObject.filterType) {
      case SearchFilterTypes.TextInput:
        return this.handleTextInput(filterObject, updatedQueryParams);

      case SearchFilterTypes.ListMultiSelect:
        return this.handleListMultiSelect(filterObject, updatedQueryParams);

      case SearchFilterTypes.ListSingleSelect:
        return this.handleListSingleSelect(filterObject, updatedQueryParams);

      case SearchFilterTypes.DropDownRange:
      case SearchFilterTypes.SingleSelectRange:
        return this.handleRange(filterObject, updatedQueryParams, isEmpty);

      case SearchFilterTypes.Pagination:
        return this.handlePagination(filterObject, updatedQueryParams);

      case SearchFilterTypes.CountyArea:
        return this.handleCountyArea(filterObject, updatedQueryParams);

      case SearchFilterTypes.MapView:
        return this.handleMapView(filterObject, updatedQueryParams);

      default:
        return this.handlebuildUrlDefault(filterObject, updatedQueryParams);
    }
  }

  /** buildUlrQueryParams helper functions **/
  private handleTextInput(filterObject: any, updatedQueryParams: any) {
    if (filterObject.name === 'terms') {
      delete updatedQueryParams[filterObject.name];
      if (filterObject.currentValue) {
        updatedQueryParams = {
          ...updatedQueryParams,
          [filterObject.name]: filterObject.currentValue,
        };
      }
      return this.SEOParams.makeReconciledQueryObject(updatedQueryParams);
    }
  }

  private handleListMultiSelect(filterObject: any, updatedQueryParams: any) {
    delete updatedQueryParams[filterObject.name];
    if (filterObject.name === 'propertyType') {
      this.SEOParams.updateCurrentValue(
        filterObject.name,
        filterObject.currentValue.values,
      );
    }

    if (
      filterObject.currentValue &&
      filterObject.currentValue.values[0] !== ''
    ) {
      updatedQueryParams = {
        ...updatedQueryParams,
        [filterObject.name]: filterObject.currentValue.values,
      };
    }
    return this.SEOParams.makeReconciledQueryObject(updatedQueryParams);
  }

  private handleListSingleSelect(filterObject: any, updatedQueryParams: any) {
    if (filterObject.name === 'propertyType') {
      this.SEOParams.updateCurrentValue(filterObject.name, filterObject.values);
      return this.SEOParams.makeReconciledQueryObject(updatedQueryParams);
    }
    const filterValue = filterObject?.values[0];
    if (filterObject.name === 'sort' && filterValue === 'bestMatch') {
      delete updatedQueryParams[filterObject.name];

      return updatedQueryParams;
    }
    if (filterValue) {
      return {
        ...updatedQueryParams,
        [filterObject.name]: filterObject.values[0],
      };
    }
    delete updatedQueryParams[filterObject.name];
    return updatedQueryParams;
  }

  private handleRange(
    filterObject: any,
    updatedQueryParams: any,
    isEmpty: string,
  ) {
    const key = filterObject.name;
    const from = `${key}_from`;
    const to = `${key}_to`;

    if (filterObject.from) {
      updatedQueryParams = {
        ...updatedQueryParams,
        [from]: filterObject.from,
      };
    }

    if (filterObject.to) {
      updatedQueryParams = {
        ...updatedQueryParams,
        [to]: filterObject.to,
      };
    }

    if (filterObject.from === isEmpty) {
      delete updatedQueryParams[from];
    }

    if (filterObject.to === isEmpty) {
      delete updatedQueryParams[to];
    }

    return updatedQueryParams;
  }

  private handlePagination(filterObject: any, updatedQueryParams: any) {
    return {
      ...updatedQueryParams,
      from: filterObject.from,
      pageSize: filterObject.pageSize,
    };
  }

  private handleCountyArea(filterObject: any, updatedQueryParams: any) {
    const { location, radius } = filterObject.currentValue;
    if (radius) {
      updatedQueryParams = {
        ...updatedQueryParams,
        radius,
      };
    } else {
      delete updatedQueryParams['radius'];
    }

    delete updatedQueryParams.mapView;
    delete updatedQueryParams.geoSearchType;
    delete updatedQueryParams.polygon;
    delete updatedQueryParams.top;
    delete updatedQueryParams.bottom;
    delete updatedQueryParams.left;
    delete updatedQueryParams.right;

    this.SEOParams.updateCurrentValue(filterObject.name, location);
    return this.SEOParams.makeReconciledQueryObject(updatedQueryParams);
  }

  private handleMapView(filterObject: any, updatedQueryParams: any) {
    const { currentValue } = filterObject;
    if (currentValue.geoSearchType === 'BBOX') {
      delete updatedQueryParams.polygon;
      delete currentValue.polygon;
    }
    delete updatedQueryParams.locationPath;
    updatedQueryParams = { ...updatedQueryParams, ...currentValue };
    return this.SEOParams.makeReconciledQueryObject(updatedQueryParams);
  }

  private handlebuildUrlDefault(filterObject: any, updatedQueryParams: any) {
    updatedQueryParams = { ...updatedQueryParams, ...filterObject };
    return this.SEOParams.makeReconciledQueryObject(updatedQueryParams);
  }

  reverseEngineerValuesFromUrlForFilters(
    searchFilters: FilterRequestAPI[] = [],
    queryParams: QueryParams,
  ): FilterRequestAPI[] {
    const reconciledSEOParamQuery =
      this.SEOParams.reconcileSEOMultiParamsFromURL(queryParams);
    return searchFilters.map((filter: FilterRequestAPI) => {
      switch (filter.searchQueryGroup) {
        case 'filters':
          return this.handleFilters(filter, reconciledSEOParamQuery);

        case 'terms':
          return this.handleTerms(filter, reconciledSEOParamQuery);

        case 'ranges':
          return this.handleRanges(filter, reconciledSEOParamQuery);

        case 'geoFilter':
          return this.handleGeoFilter(filter, reconciledSEOParamQuery);

        default:
          return this.handleReverseEngineerValuesDefaultDefault(
            filter,
            reconciledSEOParamQuery,
          );
      }
    });
  }

  /** reverseEngineerValuesFromUrlForFilters helper functions **/
  private handleFilters(
    filter: FilterRequestAPI,
    reconciledSEOParamQuery: any,
  ): FilterRequestAPI {
    const filtersCurrentValue = [reconciledSEOParamQuery[filter.name]];
    if (
      safeGet(filter, ['filterType', 'name']) ===
      SearchFilterTypes.ListMultiSelect
    ) {
      this.SEOParams.updateCurrentValue(
        filter.name,
        Array.isArray(filtersCurrentValue)
          ? filtersCurrentValue[0]
          : filtersCurrentValue,
      );
      return {
        ...filter,
        currentValue: {
          values: Array.isArray(filtersCurrentValue)
            ? filtersCurrentValue[0]
            : { values: [filtersCurrentValue] },
        },
      };
    }
    this.SEOParams.updateCurrentValue(filter.name, filtersCurrentValue);
    return { ...filter, currentValue: { values: filtersCurrentValue } };
  }

  private handleTerms(
    filter: FilterRequestAPI,
    reconciledSEOParamQuery: any,
  ): FilterRequestAPI {
    const termsCurrentValue = [reconciledSEOParamQuery[filter.name]];
    return {
      ...filter,
      currentValue: termsCurrentValue[0],
    };
  }

  private handleRanges(
    filter: FilterRequestAPI,
    reconciledSEOParamQuery: any,
  ): FilterRequestAPI {
    const from = reconciledSEOParamQuery[`${filter.name}_from`];
    const to = reconciledSEOParamQuery[`${filter.name}_to`];
    const currentValue: any = {};
    if (from) {
      currentValue.from = from;
    }
    if (to) {
      currentValue.to = to;
    }
    return {
      ...filter,
      currentValue,
    };
  }

  private handleGeoFilter(
    filter: FilterRequestAPI,
    reconciledSEOParamQuery: any,
  ): FilterRequestAPI {
    if (!reconciledSEOParamQuery[filter.name]) {
      return { ...filter };
    }
    const geoCurrentValue =
      typeof reconciledSEOParamQuery[filter.name] === 'string'
        ? [reconciledSEOParamQuery[filter.name]]
        : reconciledSEOParamQuery[filter.name];

    const seoParamObject = this.SEOParams.getParamObject(filter.name);
    const seoDefaultValue = seoParamObject && seoParamObject.defaultString;
    const indexOfDefaultValue = geoCurrentValue.indexOf(seoDefaultValue);
    if (indexOfDefaultValue !== -1) {
      geoCurrentValue.splice(indexOfDefaultValue, 1);
    }
    const radius = reconciledSEOParamQuery['radius'];

    this.SEOParams.updateCurrentValue(filter.name, geoCurrentValue);
    return {
      ...filter,
      currentValue: { radius, location: geoCurrentValue },
    };
  }

  private handleReverseEngineerValuesDefaultDefault(
    filter: FilterRequestAPI,
    reconciledSEOParamQuery: any,
  ): FilterRequestAPI {
    const defaultFilterCurrentValue = reconciledSEOParamQuery[filter.name];
    return {
      ...filter,
      currentValue: { values: defaultFilterCurrentValue },
    };
  }
}

export default UrlService;
