import React from 'react';
import * as Styled from './Tooltip.styled';
import {
  Text,
  TextSize,
  TextFontWeight,
  Icon,
  Badge,
  BadgeColor,
  BadgeStyle,
  BadgeSize,
} from '../../atoms';
import { TTooltip, TTooltipSizes } from './Tooltip.types';
import { useTooltip } from './Tooltip.hook';

export const Tooltip = (props: TTooltip) => {
  const {
    closeIcon,
    content,
    header,
    image,
    size = TTooltipSizes.MEDIUM,
    toolTipWidth = 360,
  } = props;
  const {
    active,
    boundary,
    clonedChildren,
    tooltipRef,
    badgeWidth,
    showTip,
    hideTip,
    handleCloseClick,
    getTipPosition,
  } = useTooltip(props);

  const tipPosition = getTipPosition(boundary);

  return (
    <Styled.TooltipWrapper
      onMouseEnter={showTip}
      onMouseLeave={
        closeIcon
          ? () => {
              return;
            }
          : hideTip
      }
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {active && size !== TTooltipSizes.BADGE && (
        <Styled.Tip
          className={`Tip ${getTipPosition(boundary)}`}
          data-testid={'tooltip-tip'}
          ref={tooltipRef}
          size={size}
          spanHeight={boundary.height}
          spanLeft={boundary.left}
          spanWidth={boundary.width}
          tooltipPosition={tipPosition}
          toolTipWidth={toolTipWidth}
          width={window.innerWidth}
        >
          <Styled.TextIconWrapper>
            {image && (
              <Styled.Image data-testid={'tooltip-image'}>{image}</Styled.Image>
            )}
            <Styled.TextWrapper closeIcon={closeIcon} image={image} size={size}>
              {header && (
                <Styled.HeaderWrapper>
                  <Text
                    data-testid={'tooltip-header'}
                    as="h3"
                    size={
                      size === TTooltipSizes.MEDIUM
                        ? TextSize.S14
                        : TextSize.S12
                    }
                    fontWeight={TextFontWeight.SEMIBOLD}
                  >
                    {header}
                  </Text>
                </Styled.HeaderWrapper>
              )}
              {content && (
                <Text
                  data-testid={'tooltip-content'}
                  as="div"
                  size={
                    size === TTooltipSizes.MEDIUM ? TextSize.S14 : TextSize.S12
                  }
                >
                  {content}
                </Text>
              )}
            </Styled.TextWrapper>
            {closeIcon && (
              <Styled.ClosedIcon
                data-testid={'tooltip-close'}
                onClick={handleCloseClick}
              >
                <Icon icon="CLOSE" size={16}></Icon>
              </Styled.ClosedIcon>
            )}
          </Styled.TextIconWrapper>
        </Styled.Tip>
      )}
      {active && size === TTooltipSizes.BADGE && (
        <Styled.TooltipBadge
          data-testid={'tooltip-badge'}
          ref={tooltipRef}
          spanHeight={boundary.height}
          spanLeft={boundary.left}
          spanWidth={boundary.width}
          tooltipPosition={tipPosition}
          toolTipWidth={badgeWidth}
          width={window.innerWidth}
        >
          <Badge
            badgeColor={BadgeColor.GREY_DARKER}
            badgeStyle={BadgeStyle.FILLED_DARK}
            badgeSize={BadgeSize.SMALL}
          >
            {content || ''}
          </Badge>
        </Styled.TooltipBadge>
      )}
      {clonedChildren}
    </Styled.TooltipWrapper>
  );
};
